/**
 * @flow
 */

import '@stimulus/polyfills';
import { Application } from 'stimulus';
// import Turbolinks from 'turbolinks';
// import Raven from 'raven-js';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// Config Raven
// if (window.ravenDSN) {
//   Raven.config(window.ravenDSN, { environment: window.environment }).install();
// }

// Start turbolinks
// Turbolinks.start();

// Initialize Stimulus
const application = Application.start();
const context = require.context('./controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
