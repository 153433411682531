// @flow

import { Controller } from 'stimulus';

export default class PreviewController extends Controller {
  static targets = ['control', 'preview'];

  connect() {
    this.controlTargets.forEach((control: Object) => {
      // Assigning to the DOM node, not the parameter per se
      // eslint-disable-next-line no-param-reassign
      control.checked = control.value === this.size;
    });
  }

  changeSize(event: Object) {
    this.setSize(event.target.value);
  }

  setSize(newSize: string) {
    const currentSize = this.size;

    this.size = newSize;
    this.previewTarget.classList.remove(`preview--${currentSize}`);
    this.previewTarget.classList.add(`preview--${newSize}`);
  }

  get size(): string {
    return this.data.get('size');
  }

  set size(value: string) {
    this.data.set('size', value);
  }
}
